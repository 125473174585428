import { requestData } from "Connection/transacciones"


export const ticin = async({tna, tty, eti, des, amo, tim, qde, ctl, ctp}) => {

    const jsonBody = {
        hdr: {
            txi: 41,
            req: "ticin",
            ott: true
        },
        dat: {
            tna: tna,
            tty: tty,
            eti: eti,
            des: des,
            cat: ctp,
            nim: ctl,
            // amo: amo,
            // tim: tim,
            qde: qde
        }
    }
    // console.log(jsonBody)
    return await requestData(jsonBody)
}

export default ticin

import gepic from "Connection/transacciones/gepic";
import React from "react";
import { Button } from "react-bootstrap";
import VerImagen from "./VerImagen";



function DetalleElemento({ detalle, text }) {
    return (
        <div className="row mb-1" >
            <div className="col-2">
                <label>
                    <strong>
                        {detalle}
                    </strong>
                </label>
            </div>
            <div className="col-10">
                {text.split("^").map((subText, index) => <p key={index} style={{ marginBottom: 0 }}>{subText}</p>)}
            </div>
        </div>
    )
}

function DatosGenerales({ datosTicket }) {
    const handler = async () => {
        const request = await gepic();
    }


    return (
        <div className="card-body">
            <div className="card-header detalle-ticket-header">
                <h5 className="card-title mb-0" >
                    Datos generales
                </h5>
            </div>

            <div className="card-body pb-1 pt-2 detalle-ticket-body">
                {datosTicket &&
                    <>
                        <DetalleElemento
                            detalle={"Tipo de ticket"}
                            text={datosTicket.ttd}
                        />
                        <DetalleElemento
                            detalle={"Nombre de Ticket"}
                            text={datosTicket.tna}
                        />
                        <DetalleElemento
                            detalle={"Etiqueta"}
                            text={datosTicket.eti}
                        />
                        <DetalleElemento
                            detalle={"Descripción"}
                            text={datosTicket.des}
                        />
                        <DetalleElemento
                            detalle={"Categoria de producto"}
                            text={datosTicket.cat}
                        />
                        {/* <DetalleElemento
                        detalle={"Catalogo"}
                        text={datosTicket.lnk}
                    />
                    <Button variant="primary" onClick={()=>handler()}>MOSTRAR</Button> */}

                        <div className="row mb-1" >
                            <div className="col-2">
                                <label>
                                    <strong>
                                        Informacion Adicional
                                    </strong>
                                </label>
                            </div>
                            <div className="col-10">
                                {(datosTicket.nim ===0)? <>Sin informacion adicional</>:<VerImagen idTicket={datosTicket.tid} arrGraph={datosTicket.nim} />}
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}


export default DatosGenerales;
import React from "react";
import ImageViewer from "react-simple-image-viewer";
import buttonRF from "Assets/images/rpbtn.jpg"
import { Spinner, Button } from "react-bootstrap";
import gepic from "Connection/transacciones/gepic";

function VerImagen ({idTicket, arrGraph}) {
    // console.log("arraw",arrGraph, idTicket)
    const [srcFoto, setSrcFoto] = React.useState([]);
    const [isViewerOpen, setIsViewerOpen] = React.useState(false);
    const [isFetching, setIsFetching] = React.useState(false)
    const imageFetched = React.useRef(false);

    const handleOnClick = async() => {
        if(imageFetched.current === false ) {
            setIsFetching(true);
            const graph = []
            for(let i=0; i < arrGraph; i++){
                const request = await gepic(idTicket, i+1,true);
                if(request.ans.stx === "ok") {
                    graph.push(`data:image/jpg;base64,${request.ans.pic}`)
                    imageFetched.current = true;
                }
            }
            if(graph.length > 0){
                // console.log("graph",graph)
                setSrcFoto(graph)
                imageFetched.current = true;
            }
            setIsFetching(false)
        }
        setIsViewerOpen(true);
    }

    return (
        <>
        {
            isFetching? (
                <Button variant={"secondary"}>
                    <Spinner Button animation="border" size="sm" />
                </Button>
            ):(
                <Button variant="primary" onClick={()=>handleOnClick()} >
                    Ver imagenes
                    {/* <i className={"mdi mdi-camera"}
                        style={{fontSize:"15px"}}
                        // rel="tooltip"
                        data-toggle="tooltip" 
                        data-placement="top"> 
                        </i> */}
                </Button>
            )
        }
            
            {isViewerOpen && (
                
                <ImageViewer
                    src={srcFoto}
                    currentIndex={0}
                    onClose={() => setIsViewerOpen(false)}
                    disableScroll={false}
                    backgroundStyle={{backgroundColor: "rgba(0,0,0,0.9)"}}
                    closeOnClickOutside={true}
                />
            )

            } 
        </>
    )
}

export default VerImagen
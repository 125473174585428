import tides from 'Connection/transacciones/tides';
import React from 'react'
import { Button, Modal, ModalBody } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast';

function ModalConfirm({ listOfTickets, Disabled, check, setCheck, onlyThree }) {
    const navigation = useNavigate();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const handleShow = React.useCallback(() => setIsModalOpen(true), []);
    const handleClose = React.useCallback(() => setIsModalOpen(false), []);
    const handleOnConfirm = () => {
        // localStorage.groupDetailList =  JSON.stringify(listOfTickets.map(ticket => ticket.tid));
        localStorage.groupDetailList = (listOfTickets.map(ticket => ticket.tid));
        navigation("GroupDetail")
    }
    const handlerOnRemove = async () => {
        const toRemov=[]
        listOfTickets.map(ticket => toRemov.push(ticket.tid))
        const request = await tides(toRemov)

        if (request.ans.stx === "ok") {
            handleClose()
            setCheck(!check)
            toast.success(((t) => (<>
                Tickets deshabilitados!
            </>)))
        }
        else if (request.ans.stx === "nk") {
            handleClose()
            toast.error(((t) => (<>
                {request.ans.msg}
            </>)))
        }
    }



    return (
        <div className="modal-footer">
            {/* {JSON.stringify(listOfTickets.tid)} */}
            <Button variant="danger"  onClick={() => handleShow()} disabled={!onlyThree}  >
                Deshabilitar Ticket
            </Button>
            <Modal show={isModalOpen} centered onHide={handleClose}>
                <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
                    {/* <h4>Advertencia </h4> */}
                    <i
                        className={"mdi mdi-alert-octagon"}
                        style={{ fontSize: "100px", color: "#fa6767", display: "flex", justifyContent: "center" }}
                        rel="tooltip"
                        data-toggle="tooltip"
                        data-placement="top" >
                    </i>
                </Modal.Header>

                <div style={{ fontSize: "18px", display: "flex", justifyContent: "center" }}>¿Está seguro que desea deshabilitar los siguientes tickets?</div>
                <ModalBody style={{ fontSize: "18px", display: "flex", justifyContent: "center" }}>
                    <ul>
                        {listOfTickets.map(ticket => (
                            <li key={ticket.tid}>{ticket.tna}</li>
                        ))}
                    </ul>
                </ModalBody>
                <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
                <Button variant="danger" onClick={() => handlerOnRemove()}>Deshabilitar</Button>
                    <Button variant="secondary" onClick={() => handleClose()}>Cancelar</Button>
                </Modal.Footer>

            </Modal>
            <Button onClick={handleOnConfirm} disabled={!Disabled} >
                Agrupar Tickets
            </Button>
        </div>
    )
}

export default ModalConfirm
import React, { useState } from 'react';
import BottonToggleFiltro from 'Components/Botones/BotonToggleFiltro';
import toast from "react-hot-toast";
import { Button, Collapse } from 'react-bootstrap';
import MultiSelectRetails from 'Components/Filtros/MultiSelectRetails';
import { filtrosValores } from 'Utils/filtrosHandler';
import MultiSelectLocales from 'Components/Filtros/MultiSelectLocales';
import MultiSelectRegion from 'Components/Filtros/MultiSelectRegion';
import MultiSelectComuna from 'Components/Filtros/MultiSelectComuna';
import MultiSelectKam from 'Components/Filtros/MultiSelectKam';
import MultiSelectKas from 'Components/Filtros/MultiSelectKas';
import useFiltrosTask from 'Hooks/Custom/useFiltrosTask';
import { fechaHoy } from "Utils/funcionesFechaHora";
import { useLocation } from 'react-router-dom';
import { fechaDefault, limpiarFecha } from "Utils/funcionesFechaHora";
import { forEach } from 'lodash';
const fechaDiaHoy = fechaHoy();

function FiltroTareas({totalLocales = 0, fetchLocales }) {
    
    // Vista de filtros
    const [filtros, setfiltros] = useState({
        desde_fecha: fechaDiaHoy,
        hasta_fecha: fechaDiaHoy
    })
    const [isDisabled, setIsDisabled] = useState(false);
    
    const filtroOnChange = (value, key) => {
        // console.log("h",parseInt(limpiarFecha(value)))
        if(value){
            if(key === 1){
                handleOnChange([{value:parseInt(limpiarFecha(value)),label: "desde"}],"ind")
                filtrosValores({"ind":[{value:parseInt(limpiarFecha(value)),label: "desde"}]})
            }
            else{
                
                handleOnChange([{value:parseInt(limpiarFecha(value)),label: "hasta"}],"end")
                filtrosValores({"end":[{value:parseInt(limpiarFecha(value)),label: "desde"}]})

            }}
        else{
            handleOnChange([{value:220101,label: "desde"}],"ind")
            filtrosValores({"ind":[{value:220101,label: "desde"}]})
        }
    }
    React.useEffect(() => {

        filtroOnChange(fechaDiaHoy,1)
        filtroOnChange(fechaDiaHoy,2)
        const tareas = (localStorage.groupDetailList.split(",")).map(elem=> parseInt(elem, 10))
        if(tareas){
            handleOnChange(tareas.map(elem=>({value:elem})),"tic")
                filtrosValores({"tic":[{value:tareas,label: "ticket_id"}]})
        }
        
    },[])


    // parseInt(limpiarFecha(desdeFecha)):'null'
    // console.log("fil",parseInt(limpiarFecha(filtros.desdeFecha)))
    // console.log("fil",parseInt(limpiarFecha(filtros.desde_fecha)))
    // const submitAplicarFiltros = async(event) => {
    //     event.preventDefault();
    //     if (filtros.desde_fecha > filtros.hasta_fecha || filtros.hasta_fecha > fechaDiaHoy) {
    //         toast("El rango de fechas no es valido", {
    //             icon:"⚠",             
    //         })
    //         return
    //     }
    //     setIsDisabled(true)
    //     await updateTickets(
    //         filtros.desde_fecha,
    //         filtros.hasta_fecha
    //     )
    //     setIsDisabled(false)

    // }

    const [filtrosVisibles, setFiltrosVisibles] = React.useState(true);            // State para mostrar el collapse
    const toggleFiltros = () => setFiltrosVisibles(!filtrosVisibles);
    const location = useLocation()

    const {
        // filtrosAplicados,
        isFiltrosUpdated,
        filtrosSelected, 
        handleOnChange, 
        aplicarFiltros,
        reiniciarFiltros
    } = useFiltrosTask({
        cacheKey: "f-tareas",
        session: location.key
    })



 
    return (
        <div>
            <div className='d-flex align-items-baseline mb-2'>
                {/* <div className='me-2'>
                </div> */}
                <span className='me-2'>
                    Mostrando <strong>{totalLocales}</strong> resultados
                </span>
                <span className='me-2'>
                    <BottonToggleFiltro onClick={toggleFiltros} >
                        Filtros{" "}
                    </BottonToggleFiltro>
                </span>
                <span className='text-warning ms-2'>
                    {!isFiltrosUpdated &&
                        <h4>
                            <span className="badge badge-danger-lighten">Filtros sin aplicar</span>
                        </h4>
                    }
                </span>
                
            </div>
            <Collapse in={filtrosVisibles} > 
                <div className=''>
                    
                    <div className="row">
                        <div className="row d-flex align-items-end">
                            <div className='col-3 mb-2'>
                                <label className="form-label">Desde</label>
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    defaultValue={fechaDiaHoy}
                                    max={fechaDiaHoy}
                                    // onInput={e => filtroOnChange(e.target.value,1)}
                                    onKeyDown={e => e.preventDefault()}
                                    // onChange={e => filtroOnChange(e.target.value, "desde_fecha",1)}
                                    onChange={e => filtroOnChange(e.target.value,1)}
                                    // onChange={handleOnChange}
                                    disabled={isDisabled}
                                />
                            </div>
                            <div className='col-3 mb-2'>
                                <label className="form-label">Hasta</label>
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    defaultValue={fechaDiaHoy}
                                    max={fechaDiaHoy}
                                    onKeyDown={e => e.preventDefault()}
                                    // onChange={e => filtroOnChange(e.target.value, "hasta_fecha",2)}
                                    onChange={e => filtroOnChange(e.target.value,2)}
                                    // onChange={handleOnChange}
                                    disabled={isDisabled}
                                />
                            </div>
                            <div className='col-3 mb-2'>
                                KAM
                                <MultiSelectKam 
                                    selected={filtrosSelected.kam}
                                    dependencies={filtrosValores(filtrosSelected)}
                                    onChange={handleOnChange}
                                    name="kam"
                            />
                            </div>
                                <div className='col-3 mb-2'>
                                KAS
                                <MultiSelectKas
                                    selected={filtrosSelected.kas}
                                    dependencies={filtrosValores(filtrosSelected)}
                                    onChange={handleOnChange}
                                    name="kas"
                                />
                            </div>
                        </div>
                        {/* <div className='col-3 mb-2'> 
                            Zona Geográfica
                            <MultiSelectZonaGeografica 
                                selected={filtrosSelected.zon}
                                dependencies={filtrosValores(filtrosSelected)}
                                onChange={handleOnChange}
                                name="zon"
                            />
                        </div> */}

                        <div className='col-3 mb-2'>
                            Regiones
                            <MultiSelectRegion 
                                selected={filtrosSelected.reg}
                                dependencies={filtrosValores(filtrosSelected)}
                                onChange={handleOnChange}
                                name="reg"
                            />
                        </div>

                        <div className='col-3 mb-2'>
                            Comunas
                            <MultiSelectComuna 
                                selected={filtrosSelected.com}
                                dependencies={filtrosValores(filtrosSelected)}
                                onChange={handleOnChange}
                                name="com"
                            />
                        </div>
                        
                        <div className='col-3 mb-2'>
                            Retails
                            <MultiSelectRetails 
                                selected={filtrosSelected.ret}
                                dependencies={filtrosValores(filtrosSelected)}
                                onChange={handleOnChange}
                                name="ret"
                            />
                            
                        </div>
                        <div className='col-3 mb-2'>
                            Locales
                            <MultiSelectLocales 
                                selected={filtrosSelected.loc}
                                dependencies={filtrosValores(filtrosSelected)}
                                onChange={handleOnChange}
                                name="loc"
                            />
                        </div>
                    </div>
                    <div className='text-end mb-2' >
                        <Button type='button' onClick={reiniciarFiltros} className='btn btn-secondary me-2'>
                            Limpiar filtros
                        </Button>
                        <Button type='button' variant='info' onClick={() => aplicarFiltros(fetchLocales)} disabled={false }>
                        {/* <Button type='button' variant='info' onClick={() => aplicarFiltros(fetchLocales)} disabled={isFiltrosUpdated }> */}
                            Aplicar filtros
                        </Button>
                    </div>

                    
                </div>
                
            </Collapse>
            

        </div>
    )
}

export default FiltroTareas;


import { requestData } from "Connection/transacciones"

const locrp = async (dat = {}, option = 0) => {
    const jsonBody = {
        hdr: {
            txi: 90,
            req: "locrp",
            ott: true,
        },
        dat: {
            ...dat
        }
    }
    // console.log("locrp", jsonBody)
    return await requestData(jsonBody)

}

export default locrp;
import React, { useEffect, createRef } from 'react'
import { Button, Form } from 'react-bootstrap'
// import catad from 'Connection/transacciones/catad'
import matrx from 'Connection/transacciones/matrx'
import catad from 'Connection/transacciones/catad'
import useDatosUsuarioWeb from 'Hooks/Datos/useDatosUsuarioWeb'
import { Link } from 'react-router-dom'
import Dropzone from 'react-dropzone'
import { set } from 'lodash'
import VerFoto from './VerFoto'


function DatosGenerales({ formulario, cliente, files, setFiles }) {

    const [catalogoOpc, setcatalogoOpc] = React.useState([])
    // const [selectedFile, setSelectedFile] = React.useState(null);

    const dropzoneRef = createRef();
    const openDialog = () => {
        // Note that the ref is set async,
        // so it might be null at some point 
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
    };
    const onDrop = (data) => {
        // if (files.length > 0) {
        // const reader = new FileReader()
        // print file in base64
        // reader.readAsDataURL(files[0])
        // reader.onload = () => {
        //     testo(reader.result)
        // }
        const arr = [...files]
        data.map((file) => {
            if (file.type !== "image/jpeg" && file.type !== "image/png") {
                // alert("Solo se permiten imagenes en formato jpg o png")
                return
            }
            if (arr.length > 5) {
                // alert("Solo se permiten 5 imagenes")
                return
            }
            arr.push(file)
        })
        setFiles(arr)
        // testo(files)
        // setSelectedFile(files);
        // console.log(selectedFile)
        // setSrcFoto([`data:image/jpg;base64,${request.ans.pic}`])
        // console.log(selectedFile)
        // }
    };
    const onRemove = (index) => {
        const arr = [...files]
        arr.splice(index, 1)
        setFiles(arr)
    }

    React.useEffect(() => {
        const run = async () => {
            // const request = await matrx({   
            //     trx:"catad",
            //     cid:cliente
            // });
            const request = await catad({
                cid: cliente
            });
            // const request = await gecat(cliente);
            const options = request.ans.cat.map(opt => ({ label: opt.nam, value: opt.cod }));
            setcatalogoOpc(options)
        }
        run()
    }, [])
    return (
        <>

            <div className="card-header detalle-ticket-header">
                <h5 className="card-title mb-0" >
                    Datos generales
                </h5>
            </div>
            <div className="card-body pb-1 pt-2 detalle-ticket-body">
                <div className="row mb-2">
                    <div className='col-2'>
                        <label htmlFor="tipoTicket" className="col-form-label">
                            Tipo de Ticket
                        </label>
                    </div>
                    <div className='col-5'>
                        <Form.Select
                            id='tipoTicket'
                            value={formulario.values.tipoTicket}
                            onChange={formulario.handleChange}
                            required
                        >
                            <option value="2">Reposición</option>
                            <option value="1">Auditoría</option>
                            <option value="3">Implementación</option>
                        </Form.Select>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className='col-2'>
                        <label htmlFor="nombreTicket" className="col-form-label">
                            Nombre Ticket
                        </label>
                    </div>
                    <div className='col-5'>
                        <Form.Control
                            value={formulario.values.nombreTicket}
                            onChange={formulario.handleChange}
                            id='nombreTicket'
                            type='text'
                            required
                        />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className='col-2'>
                        <label htmlFor="etiqueta" className="col-form-label">
                            Etiqueta
                        </label>
                    </div>
                    <div className='col-5'>
                        <Form.Control
                            value={formulario.values.etiqueta}
                            onChange={formulario.handleChange}
                            id='etiqueta'
                            type='text'
                            required
                        />

                    </div>
                </div>
                <div className="row mb-2">
                    <div className='col-2'>
                        <label htmlFor="descripcion" className="col-form-label">
                            Descripción
                        </label>
                    </div>
                    <div className='col-5'>
                        <Form.Control
                            as='textarea'
                            id='descripcion'
                            rows={4}
                            value={formulario.values.descripcion}
                            onChange={formulario.handleChange}
                            type='text'
                            maxLength={1024}
                            required
                        >

                        </Form.Control>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className='col-2'>
                        <label htmlFor="categoriaProducto" className="col-form-label">
                            Categoria de producto
                        </label>
                    </div>
                    <div className='col-5'>
                        <Form.Select
                            id='categoriaProducto'
                            value={formulario.values.Catprod}
                            onChange={formulario.handleChange}
                            defaultValue={""}
                            required
                        >
                            <option disabled value="">Seleccionar elemento</option>
                            {catalogoOpc.map((opt, i) => (
                                <option value={(opt.value)} key={i}>{opt.label}</option>
                            )
                            )}
                        </Form.Select>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className='col-2'>
                        <label htmlFor="Catalogo" className="col-form-label">
                            Informacion adicional
                        </label>
                    </div>
                    <div className='col-5'>
                        {/* <Form.Control 
                            value={formulario.values.catalogo}
                            onChange={formulario.handleChange}
                            id='catalogo' 
                            type='text'
                            required 
                        /> */}
                        <Dropzone ref={dropzoneRef} onDrop={onDrop} noClick noKeyboard>
                            {({ getRootProps, getInputProps, acceptedFiles }) => {
                                return (
                                    <>
                                    <input {...getInputProps()} />
                                        <Button size="sm" variant="primary" onClick={() => openDialog()} disabled={(files.length > 5)}>Agregar imagen</Button>
                                        <ul style={{ "padding": "10px" }}>
                                            {/* {acceptedFiles.map(file => (
                                            <li key={file.path}>
                                            {file.path}
                                            </li>
                                            ))} */}
                                            {files.map((file, index) => (
                                                <div className="row" >
                                                    <div className="col-5" style={{ "overflow": "hidden" }}>
                                                        <li key={index} style={{ "marginLeft": "5px" }}>
                                                            {/* {file.path} */}
                                                            <VerFoto image={file} />
                                                        </li>
                                                    </div>
                                                    <div className="col-2">
                                                        <Button variant="link" size="sm" onClick={() => onRemove(index)}
                                                            style={{ "padding": "0px", "color": "gray" }}>
                                                            x
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                        </ul>
                                    {/* </div> */}
                                    </>
                                )
                            }}
                        </Dropzone>
                    </div>
                </div>

            </div>
        </>
    )
}

export default DatosGenerales
import matrx from "Connection/transacciones/matrx";
import React from "react";
import toast from 'react-hot-toast';
import catad from "Connection/transacciones/catad";

function useListaCategorias(datosUsuario) {
    const [isFetching, setIsFetching] = React.useState(false);
    const [catalogo, setCatalogo] = React.useState([]);

    const handlerData = React.useCallback(async(tags) => {
        // console.log({
        //     trx: "catad",
        //     cid:datosUsuario,
        //     ...tags
        // })
        // console.log(tags)
        setIsFetching(true)
        // const request = await matrx({
        //     trx: "catad",
        //     cid:datosUsuario,
        //     ...tags
        // });
        const request = await catad({
            cid:datosUsuario,
            ...tags
        });
        setCatalogo(request.ans.cat || [])
        setIsFetching(false)
        
    },[])

    const handlerAddTag =(datos,option)=>{
        // console.log(datos)
        switch (option){
            case 1:
                handlerData({new:datos})
                break;
        
            case 2:
                handlerData({des:datos})
                break;
        
            case 3:
                handlerData({upd:datos})
                break;

            default:
                break;
    }
}
    

    // const agregarCategoria = (NombreCategoria) => handlerAddTag(NombreCategoria)
    // const eliminarCategoria = (ListaCategoria) => handlerDesTag(ListaCategoria)
    // const modificarCategoria = (NombreCategoria) => handlerUpdTag(NombreCategoria)

    const agregarCategoria = (NombreCategoria) => handlerAddTag(NombreCategoria,1)
    const eliminarCategoria = (ListaCategoria) => handlerAddTag(ListaCategoria,2)
    const modificarCategoria = (NombreCategoria) => handlerAddTag(NombreCategoria,3)


    return {
        isFetching,
        catalogo,
        handlerData,
        agregarCategoria,
        eliminarCategoria,
        modificarCategoria
    }
}
export default useListaCategorias
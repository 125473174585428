import { requestData } from "Connection/transacciones"

const taskp = async(tic, qid, file) => {

    const jsonBody = {	
        hdr:	{	
            txi: 26,
            req: "taskp",
            ott: true,
        },	
        dat:{	
            tic:tic,
            qid:qid,
            file:file

        }	
    }
    // console.log(jsonBody)
    return await requestData(jsonBody)
}

export default taskp;
import { requestData } from "Connection/transacciones"

const tides = async(tid) => {
    const jsonBody = {
        hdr: {
            txi: 98,
            req: "tides",
            ott: true,
        },
        dat: {
            tid:tid 
        }
    }
    // console.log("tides", jsonBody)
    return await requestData (jsonBody)

}
export default tides

import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Button, Spinner } from 'react-bootstrap'
import { sleep } from 'Utils/time'
import Requerimientos from './Requerimientos'
import DatosGenerales from './DatosGenerales'
import toast from 'react-hot-toast'
import ticin from 'Connection/transacciones/ticin'
import { useLocation, useNavigate } from 'react-router-dom'
import ticks from 'Connection/transacciones/ticks'
import ticde from 'Connection/transacciones/ticde'
// import gecat from 'Connection/transacciones/catad'
import useDatosUsuarioWeb from 'Hooks/Datos/useDatosUsuarioWeb'
import taskp from 'Connection/transacciones/taskp'
import gepic from 'Connection/transacciones/gepic'

function FormularioCrearTicket() {
    // const { selectedFile, onFileChange, onFileRemove } = useFiles()
    const [selectedFile, setSelectedFile] = React.useState([]);

    // const [selectedFile, setSelectedFile] = React.useState(null);

    // const dropzoneRef = createRef();
    // const openDialog = () => {
    //     // Note that the ref is set async,
    //     // so it might be null at some point 
    //     if (dropzoneRef.current) {
    //         dropzoneRef.current.open()
    //     }
    // };
    // const onDrop = (files) => {
    //     // if (files.length > 0) {
    //     setSelectedFile(files);
    //     console.log(selectedFile)
    //     // }

    // };

    const [isSubmiting, setIsSubmiting] = useState(false);
    const detalleUsuarioWeb = useDatosUsuarioWeb();
    const location = useLocation()
    const navi = useNavigate()
    const formulario = useFormik({
        initialValues: {
            tipoTicket: '2',
            nombreTicket: '',
            etiqueta: '',
            descripcion: '',
            categoriaProducto: '',
            info: '',
            listaRequerimientos: [{ id: 0, tipo: 1, titulo: '', next: '' }]
        },
        onSubmit: async (values) => {
            let validation = false
            let a = 1
            // setIsSubmiting(true)
            const req = values.listaRequerimientos.map((val, i) => {
                const dat = {
                    aty: val.tipo,
                    tiq: val.titulo,
                    qid: parseInt(i + 1, 10),
                    nxt: parseInt(val.next, 10)
                }
                if ([2, 3].includes(dat.aty)) {
                    dat.alt = val.alternativas.map(alt => alt.value)
                }
                if ((i + 1 === values.listaRequerimientos.length && (parseInt(val.next, 10) !== 0 || parseInt(val.tipo, 10) === 8)) || (parseInt(val.next, 10) <= i + 1 && parseInt(val.next, 10) !== 0)) {
                    toast.error("Error de secuencia ingresada", {
                        duration: 4000
                    })
                    setIsSubmiting(false)
                    validation = true
                    return null
                }
                if ([8].includes(dat.aty)) {
                    dat.al1 = val.alternativa1.value
                    dat.nx2 = parseInt(val.next2, 10)
                    dat.al2 = val.alternativa2.value
                    if ((parseInt(val.next2, 10) > values.listaRequerimientos.length || parseInt(val.next2, 10) < 0) || (parseInt(val.next2, 10) <= i + 1 && parseInt(val.next2, 10) !== 0)) {
                        toast.error("Error de secuencia ingresada", {
                            duration: 4000
                        })
                        setIsSubmiting(false)
                        validation = true
                    }
                }
                if (parseInt(val.next, 10) > values.listaRequerimientos.length || parseInt(val.next, 10) < 0) {
                    toast.error("Error de secuencia ingresada", {
                        duration: 4000
                    })
                    setIsSubmiting(false)
                    validation = true
                }
                return (dat)
            })
            if (validation) {

                setIsSubmiting(false)
                return null
            }

            const data = await ticin({
                tna: values.nombreTicket,
                tty: parseInt(values.tipoTicket, 10),
                eti: values.etiqueta,
                des: values.descripcion,
                ctp: parseInt(values.categoriaProducto, 10),
                // ctl: values.info,
                ctl: selectedFile.length,
                // amo: 1000,
                // tim: 120,
                qde: req
            }
            )

            if (data.ans.stx === "ok") {
                selectedFile.map(async (file, index) => {
                    if(file.loaded===true){
                        const request = await taskp(data.ans.tic, index + 1, file.pic)
                        // const request = await taskp("test", index + 1, file.pic)
                    }
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = async () => {
                        const request = await taskp(data.ans.tic, index + 1, reader.result.split(",")[1])
                        // const request = await taskp("test",index + 1, reader.result.split(",")[1])
                    }
                })
                toast.success(data.ans.msg, {
                    duration: 4000
                })
                await sleep(1000)
                navi("/ListaTickets")
            } else {
                toast.error(data.ans.msg, {
                    duration: 4000
                })
                setIsSubmiting(false)
            }
        },
    })
    const copiarTicket = async (idTicket) => {
        const requestDatosGenerales = await ticks({
            idTicket: idTicket,
            cantidad: 1
        })
        const requestDetalleTicket = await ticde(idTicket)
        if (requestDatosGenerales.ans.stx === "ok" && requestDetalleTicket.ans.stx === "ok") {
            const datosTicket = requestDatosGenerales.ans.tas[0];
            const requerimientos = requestDetalleTicket.ans.tas.map((req, idx) => {
                let item = {
                    "id": idx,
                    "tipo": req.aty,
                    "titulo": req.tiq,
                }
                if ([2, 3].includes(item.tipo)) {
                    item.alternativas = req.alt.map((opcion, idx) => ({
                        "value": opcion.txt,
                        "id": idx
                    }))
                }
                return item;
            })
            formulario.setValues({
                tipoTicket: datosTicket.tti,
                nombreTicket: datosTicket.tna,
                etiqueta: datosTicket.eti,
                descripcion: datosTicket.des.replaceAll("^", "\n"),
                categoriaProducto: datosTicket.ctp,
                info: datosTicket.ctl,
                listaRequerimientos: requerimientos
            })
            let files = []
            for(let i=0;i<datosTicket.nim;i++){
                // console.log(datosTicket.nim)
                const request = await gepic(idTicket, i+1, true)
                if(request.ans.stx === "ok"){
                    files.push({name: "imagen_"+i, loaded:true,pic: request.ans.pic})
                }
                

                // console.log(request)
                // if(request.ans.stx === "ok"){
                    // files.push({name: "imagen_"+i+1, loaded:true,pic: request.ans.pic})
                    // setSelectedFile([...selectedFile, {name: "imagen_"+i, loaded:true,pic: request.ans.pic}])
                // }
            }
            setSelectedFile(files)

        }
    }

    useEffect(() => {
        if (location.pathname.toLowerCase() === '/ListaTickets/ClonarTicket'.toLocaleLowerCase()) {
            let idTicket = sessionStorage.getItem(location.key) // obtiene los valores del sessionStorage
            if (!idTicket) { // si no hay valor en sessionStorage 
                idTicket = localStorage.getItem("tic_id")
                sessionStorage.setItem(location.key, idTicket)
                localStorage.removeItem("tic_id")
            }
            if (idTicket) {
                // console.log("copiar ticket", idTicket); 
                copiarTicket(idTicket)
            }
        }

        const run = async () => {
            await detalleUsuarioWeb.refreshData();
        }
        run();
    }, []);


    // const reqValidate = React.useMemo(()=>{
    //     if (formulario.values.listaRequerimiento) {
    //     formulario.values.listaRequerimiento.map((val)=>{
    //         if (parseInt(val.next,10) >formulario.values.listaRequerimiento.length) {
    //             return console.log("error")
    //         }
    //     })
    // }
    // },[formulario.values.listaRequerimiento])

    if (detalleUsuarioWeb.isFetching) {
        return (
            <div className='text-center'>
                <Spinner animation='border'></Spinner>
            </div>
        )
    }
    if (detalleUsuarioWeb.datos === null) {
        return null
    }

    return (
        <div className="card">
            <div className="card-body">
                <form onSubmit={formulario.handleSubmit}>
                    <DatosGenerales formulario={formulario} cliente={detalleUsuarioWeb.datos.clie} files={selectedFile} setFiles={setSelectedFile} />
                    {/* <Button onClick={()=>console.log(selectedFile)}>MOSTRAR</Button> */}

                    <br />
                    <Requerimientos formulario={formulario} />


                    <div className='d-flex justify-content-end mt-2'>
                        <Button type='submit' disabled={isSubmiting} >
                            <div style={{ width: "78px" }} className="text-center" >
                                {isSubmiting && <Spinner animation="border" size='sm' />}
                                {!isSubmiting && "Crear Ticket"}
                            </div>

                        </Button>
                        {/* <Button variant='secondary' className='ml-2' onClick={()=>console.log(formulario.values.listaRequerimientos)}> MOSTRAR </Button>

                    <Button variant='secondary' className='ml-2' onClick={()=>console.log(formulario.values.listaRequerimientos)}> MOSTRAR2 </Button> */}

                    </div>
                </form>

            </div>
        </div>
    )
}

export default FormularioCrearTicket
import auten from "Connection/transacciones/auten"
import toast from "react-hot-toast";
import { clean } from "Utils/rut";
import { sleep } from "Utils/time";


const SESSION_INFO = 'sessionInfo'
const OTT = 'ott'

export const getOtt = () => localStorage[OTT]
export const setOtt = (newOtt) => localStorage[OTT] = newOtt
export const isAuthenticated = () => {
    return localStorage.ott? true: false
}

export const logout = () => {
    delete localStorage[SESSION_INFO]
    delete localStorage[OTT];
    sleep(500)
    window.location.replace("/")
}

const getSessionInfo = () => localStorage[SESSION_INFO]? JSON.parse(localStorage[SESSION_INFO]): undefined

export const getProfileOptions = () => getSessionInfo()?.pro;
export const getMst = () => getSessionInfo()?.mst
export const getMac = () => getSessionInfo()?.mac
export const getMop = () => getSessionInfo()?.mop
export const getUser = () => getSessionInfo()?.userSession 
export const getName = () => getSessionInfo()?.nam
export const getClient = () => getSessionInfo()?.clien
export const getClientID = () => getSessionInfo()?.clie
export const getCountry = () => getSessionInfo()?.pai
export const getCluster = () => getSessionInfo()?.clu

export const login = async(user, password) => {
    const request = await auten(user, password);
    // console.log(request)
    if(request.ans.stx === 'ok') {
        const clie = request.ans.cli.split("-")
        const sessionInfo = {
            mst: request.ans.mst, // estrellas
            mac: request.ans.mac, // acciones
            mop: request.ans.mop, // //maximo opciones en seleccion unica o multiple
            nam: request.ans.nam, // nombre session
            clien: clie[1], //nombre cliente
            clie: clie[0], //id cliente
            userSession: clean(user).toUpperCase(), //rut
            pro: request.ans.pro, //  acceso a los items del dashboard (posiblemente para proteger rutas)
            pai: request.ans.pai,
            clu: request.ans.clu
        }
        localStorage[SESSION_INFO] = JSON.stringify(sessionInfo);
        
        toast.success(request.ans.msg)
        await sleep(1000)
        return true;
    } else if (request.ans.stx === 'wk' || request.ans.stx === 'nk') {
        toast.error(request.ans.msg)
        return false;
    }
}
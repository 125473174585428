import Tabla from 'Components/Tabla/Tabla';
import React from 'react';
import { Link } from 'react-router-dom';
import ticks from "Connection/transacciones/ticks";
import { formatearFecha } from "Utils/funcionesFechaHora";
import { useSetRecoilState } from "recoil"
import { isModalOpcionesOpenState, ticketSeleccionadoState, ticketNombreState } from './ModalOpcionesTickets';
import { Button } from 'react-bootstrap';
import ModalConfirm from './ModalConfirm';
const thClassName= "ps-1"



function TablaTickets (){
    const [check, setCheck] = React.useState(false)
    const setIsModalOpen = useSetRecoilState(isModalOpcionesOpenState)
    const setTicketSeleccionado = useSetRecoilState (ticketSeleccionadoState)
    const nombreTicketSeleccionado = useSetRecoilState (ticketNombreState)
    const abrirModal = (idTicket) => {
        setIsModalOpen (true)
        setTicketSeleccionado(idTicket)
    }
    const [tickets, setTickets] = React.useState([])
    const [fetching, setIsFetching] = React.useState(false);
    React.useEffect(async() => {
        setIsFetching(true);
        const request = await ticks({})
        setTickets(request.ans.tas || [])
        setIsFetching(false);
    }, [check]);


    const columnas = React.useMemo(() => [
        {
            Header: 'Nombre Ticket', 
            accessor: 'tna',
            Cell: (props) => {
                const {value, row} = props
                 return (
                    <Link  to="#" onClick={(e)=>{
                        e.preventDefault()
                        nombreTicketSeleccionado(row.original.tna)
                        abrirModal(row.original.tid)
                    }}> 
                    {value}
                    </Link>
                 )
             },
            thClassName
        },{
            Header: 'Creación',
            accessor: item => formatearFecha(item.crt),
            thClassName
        },{
            Header: 'Requerimientos',
            accessor: item => item.nqu + item.nre + item.npi,
            thClassName
        },{
            Header: 'Creador',
            accessor: 'own',
            thClassName
        }


    ] ,[]) 
    
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [isDisabled, setDisabled]= React.useState(false);
    const [selectLimited, setSelectLimited] = React.useState(false);

    const validSelect = React.useMemo(()=> {
        if(selectedItems.length === 0) return false
        else return true;
    },[selectedItems])

    const onlyThree = React.useMemo(()=> {
        if(selectedItems.length > 3 || selectedItems.length ===0) return false
        else return true;
    },[selectedItems])
    
    // const handleGoToResume = () => {
    //     console.log("items",selectedItems);
    // }
    
    return(
        <>
        <div className='card'>
        <div className="card-body">
            <Tabla
                columns={columnas}
                data={tickets}
                globalFilter={true}
                // titleSection={""}
                tableClass={"table table-centered table-hover  mb-0"}
                theadClassName={"table-light"}
                cellClassName={"p-1"}
                loading={fetching}
                select
                onSelected={(selected) => setSelectedItems(selected)}
            /> 
            {/* <Button onClick={handleGoToResume}>
                asdasdasd
            </Button> */}
            <ModalConfirm listOfTickets={selectedItems} Disabled={validSelect} check={check} setCheck={setCheck} onlyThree={onlyThree} />
        </div>

    </div>
    </>
)
}


export default TablaTickets
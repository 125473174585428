import React from "react";
import ImageViewer from "react-simple-image-viewer";

import { Button, Spinner } from "react-bootstrap";
import { set } from "lodash";

function VerFoto({ image }) {
    // console.log("arraw",arrGraph)
    // console.log("image",image)
    const [srcFoto, setSrcFoto] = React.useState([]);
    const [index, setIndex] = React.useState(0)
    const [isViewerOpen, setIsViewerOpen] = React.useState(false);
    const [isFetching, setIsFetching] = React.useState(false)
    const imageFetched = React.useRef(false);

    React.useEffect(() => {
        setIsFetching(true);
        const file = []
        file.push(image.name)
        if (image.loaded === true) {
            file.push(`data:image/jpg;base64,${image.pic}`)
            setSrcFoto(file)
        }
        else {
            const reader = new FileReader()
            reader.readAsDataURL(image)
            reader.onload = () => {
                file.push(reader.result)
            }
            // console.log("file", file)
            setSrcFoto(file)
        }
        setIsFetching(false)
    }, [])


    const handleOnClick = () => {
        setIsViewerOpen(true);
        // console.log("srcFoto", srcFoto)
    }

    return (
        <>
            {
                isFetching ? (
                    <Spinner animation="border" size="sm" />
                ) : (
                    <Button variant="link" onClick={handleOnClick} style={{ "padding": "0px", "overflow": "hidden", "textOverflow": "ellipsis", "maxWidth": "195px", "whiteSpace": "nowrap" }}>
                        {srcFoto[0]}
                    </Button>
                )
            }
            {/*  */}
            {isViewerOpen && (

                <ImageViewer
                    src={[srcFoto[1]]}
                    currentIndex={index}
                    onClose={() => setIsViewerOpen(false)}
                    disableScroll={false}
                    backgroundStyle={{ backgroundColor: "rgba(0,0,0,0.9)" }}
                    closeOnClickOutside={true}
                />
            )

            }
        </>
    )
}

export default VerFoto
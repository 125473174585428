import { requestData } from "Connection/transacciones"
import { parseInt } from "lodash";

const gepic = async (idTarea, idRequerimiento, det) => {
    if (det === true) {
        const jsonBody = {
            hdr: {
                txi: 70,
                req: "gepic",
                ott: true,
            },
            dat: {
                tic: parseInt(idTarea, 10),
                qid: parseInt(idRequerimiento, 10),

            }
        }
        // console.log(jsonBody)
        return await requestData(jsonBody)
    }
    else {
        const jsonBody = {
            hdr: {
                txi: 70,
                req: "gepic",
                ott: true,
            },
            dat: {
                tid: parseInt(idTarea, 10),
                qid: parseInt(idRequerimiento, 10),

            }
        }
        // console.log(jsonBody)
        return await requestData(jsonBody)
    }

}

export default gepic;
import React, { useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import { backend_request } from "Connection/backend_reduce";
import { fechaHoy } from "Utils/funcionesFechaHora";

const fechaDiaHoy = fechaHoy();

function FiltroTareas ({updateTickets}){
    // filtros
    const [filtros, setfiltros] = useState({
        desde_fecha: fechaDiaHoy,
        hasta_fecha: fechaDiaHoy,
        // supervisor_id: undefined,
        // vendedor_id: undefined,
        // zolber_id: undefined,
    })
    // lista de supervisores
    // const [supervisores, setSupervisores] = useState({
    //     fetched: false,
    //     lista: [{value: undefined, label: ""}]
    // })
    // // lista vendedores
    // const [vendedores, setVendedores] = useState({
    //     fetched: false,
    //     lista: [{value: undefined, label: ""}]
    // })
    const [isDisabled, setIsDisabled] = useState(false);

    const renonbrarItemLista = (item) => {
        return {
            value: item.cod,
            label: item.nam
          };
    }
    // const getBackendSupervisores= async() => {
    //     if (supervisores.fetched === true) return; // 
    //     let json_body = {
    //         "hdr":	{	"txi": 69,
    //             "req": "geper",
    //             "ott": localStorage.ott
    //         },	
    //         "dat":	{	
    //             "lev":10
    //         }	
    //     }
    //     setIsDisabled(true)
    //     await backend_request(json_body,  (data)=> {
    //         let lista_renombrada = data.ans.per.map((item)=> renonbrarItemLista(item))
    //         lista_renombrada.unshift({value:undefined,  label: "-"})
    //         setSupervisores({
    //             fetched: true,
    //             lista: lista_renombrada
    //         })
    //     });
    //     setIsDisabled(false)
    // }
    // const getBackendVendedores = async() => {
    //     if (vendedores.fetched === true) return; // 
    //     let json_body = {
    //         "hdr":	{	"txi": 69,
    //             "req": "geper",
    //             "ott": localStorage.ott
    //         },	
    //         "dat":	{	
    //             "lev":1
    //         }	
    //     }
    //     setIsDisabled(true);
    //     await backend_request(json_body,  (data)=> {
    //         let lista_renombrada = data.ans.per.map((item)=> renonbrarItemLista(item))
    //         lista_renombrada.unshift({value:undefined,  label: "-"})
    //         setVendedores({
    //             fetched: true,
    //             lista: lista_renombrada
    //         })
    //     });
    //     setIsDisabled(false);
    // }
    const filtroOnChange = (value, key) => {
        setfiltros({
            ...filtros,     // mantiene los valores
            [key]: value,   // solo cambia lo de la key
        })
    }
    const submitAplicarFiltros = async(event) => {
        event.preventDefault();
        if (filtros.desde_fecha > filtros.hasta_fecha || filtros.hasta_fecha > fechaDiaHoy) {
            toast("El rango de fechas no es valido", {
                icon:"⚠",             
            })
            return
        }
        setIsDisabled(true)
        await updateTickets(
            // localStorage.tic_id,
            filtros.desde_fecha,
            filtros.hasta_fecha,
            // filtros.supervisor_id,
            // filtros.vendedor_id,
        )
        setIsDisabled(false)

    }

    return (
        <div className="mb-2 ">
            <h4>Filtros</h4>
            <form onSubmit={submitAplicarFiltros}>
                <div className="row d-flex align-items-end">
                    <div className="col-3">
                        <label className="form-label">Desde</label>
                        <input 
                            type="date" 
                            className="form-control" 
                            defaultValue={fechaDiaHoy}
                            max={fechaDiaHoy}
                            onChange={e => filtroOnChange(e.target.value, "desde_fecha")}
                            disabled={isDisabled}
                            onKeyDown={e => e.preventDefault()}
                        />
                    </div>
                    <div className="col-3">
                        <label className="form-label">Hasta</label>
                        <input 
                            type="date" 
                            className="form-control" 
                            defaultValue={fechaDiaHoy}
                            max={fechaDiaHoy}
                            onChange={e => filtroOnChange(e.target.value, "hasta_fecha")}
                            disabled={isDisabled}
                            onKeyDown={e => e.preventDefault()}
                        />
                    </div>
                    {/* <div className="col-3">
                        <label className="form-label">Supervisor</label>
                        <Select
                            options={supervisores.lista} 
                            placeholder={"-"}
                            onChange={selected => filtroOnChange(selected.value, "supervisor_id")}
                            onMenuOpen={getBackendSupervisores}
                            isDisabled={isDisabled}
                        />
                    </div>
                    <div className="col-3">
                        <label className="form-label">Vendedor</label>
                        <Select 
                            options={vendedores.lista} 
                            placeholder={"-"}
                            onChange={selected => filtroOnChange(selected.value, "vendedor_id")}
                            onMenuOpen={getBackendVendedores}
                            isDisabled={isDisabled}
                        />
                    </div> */}
                    <div className="col d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary" disabled={isDisabled}>
                            {!isDisabled && "Aplicar filtros"}
                            {isDisabled &&
                                <span className="spinner-border spinner-border-sm" role="status">

                                </span>
                            }
                        </button>
                    </div>
                </div>
                
            </form>
        </div>

    )
}

export default FiltroTareas;
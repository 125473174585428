import { requestData } from "Connection/transacciones"
import { clean } from "Utils/rut";
// const APP_BUILD_VERSION = process.env.REACT_APP_BUILD;
const APP_BUILD_VERSION = process.env.REACT_APP_VERSION;
const ORIGIN = 'web'

const auten = async(user, password) => {
    // eliminar puntos
    const version = APP_BUILD_VERSION.replace(/\./g, '');
    
    const jsonBody = {
        hdr: {
            txi: 1,
            req: 'auten',
            ott: false
        },
        dat: {
            usr: clean(user).toUpperCase(),
            psw: password,
            lat: 1,
            lon: 1,
            phid: 'null',
            orig: ORIGIN,
            vers: Number(version)
        }
    }
    return await requestData(jsonBody)
}
export default auten;